<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="mr-1"
                color="grey darken-3"
                :to="{
                  name: `${page_route}${!id ? '' : '.view'}`,
                  params: { id: id },
                }"
              >
                <v-icon v-text="'mdi-arrow-left'" />
              </v-btn>
            </template>
            <span v-text="'Atrás'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="data_form" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      counter
                      maxlength="50"
                      type="text"
                      label="Nombre*"
                      v-model="data.name"
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      counter
                      maxlength="25"
                      type="text"
                      label="A. paterno*"
                      v-model="data.first_surname"
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      counter
                      maxlength="25"
                      type="text"
                      label="A. materno"
                      v-model="data.second_surname"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      counter
                      maxlength="10"
                      type="text"
                      label="ID SM"
                      v-model="data.enrollment"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      counter
                      maxlength="50"
                      type="email"
                      label="C. electrónico*"
                      v-model="data.email"
                      :rules="rules.email"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      dense
                      counter
                      maxlength="20"
                      type="text"
                      label="Fecha*"
                      v-model="data.email_date"
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      dense
                      label="Mensaje"
                      v-model="data.message"
                      rows="2"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'DOCUMENTOS'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items />
              </v-toolbar>
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(document, i) in data.documents"
                    :key="i"
                  >
                    <v-row dense v-if="document.active">
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          dense
                          counter
                          maxlength="100"
                          type="text"
                          :label="`Descripción (${i + 1})*`"
                          v-model="document.description"
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="5">
                        <v-file-input
                          dense
                          show-size
                          label="Archivo digital*"
                          v-model="document.file"
                          accept="image/*,.pdf"
                          :rules="data.id ? [] : rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="1" class="text-right">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              dark
                              small
                              v-on="on"
                              class="mt-1"
                              color="error"
                              @click.prevent="documentDelete(i)"
                            >
                              <v-icon medium v-text="'mdi-file-remove'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar documento (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider class="pb-1" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          fab
                          dark
                          x-small
                          v-on="on"
                          class="mr-1"
                          color="warning"
                          @click.prevent="documentAdd"
                          :loading="loading"
                        >
                          <v-icon v-text="'mdi-file-plus'" />
                        </v-btn>
                      </template>
                      <span v-text="'Agregar documento'" />
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  small
                  v-on="on"
                  class="ml-1"
                  :loading="loading"
                  :color="!id ? 'success' : 'info'"
                  @click.prevent="handleSubmit"
                >
                  <v-icon v-text="!id ? 'mdi-plus' : 'mdi-pencil'" />
                </v-btn>
              </template>
              <span v-text="!id ? 'Crear' : 'Editar'" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  toFormData,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      page_route: "contacto_mensajes",
      api: "contact/messages",
      login: this.$store.getters.getLogin,
      loading: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      data: {
        id: null,
        name: "",
        first_surname: "",
        second_surname: "",
        email: "",
        message: "",
        enrollment: "",
        active: 1,
        insured_id: null,
        documents: [],
        email_date: "",
      },
      rules: rules(),
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },

  methods: {
    documentAdd() {
      this.data.documents.push({
        id: null,
        description: "",
        url: null,
        active: true,
        documentid: 1,
        contact_message_id: null,
      });
    },
    documentDelete(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
    handleSubmit() {
      if (this.$refs.data_form.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${!this.id ? "creación" : "edición"} del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              let data_form = toFormData(this.data);

              if (this.id) {
                data_form.append("_method", "PATCH");
              }

              Axios.post(
                `${URL_API}/${this.api}${this.id ? `/${this.id}` : ""}`,
                data_form,
                headersToken(this.login.token)
              ).then((response) => {
                // console.log(response.data);
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                response.data.success
                  ? this.$router.push({
                      name: `${this.page_route}${!this.id ? "" : ".view"}`,
                      params: { id: this.id },
                    })
                  : console.log(response.data.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    if (this.id) {
      this.loading = true;
      Axios.get(
        `${URL_API}/${this.api}/${this.id}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.data = response.data.data;
        this.loading = false;
      });
    }
  },
};
</script>